import Axios from "axios";
import { compose, assoc, filter, equals, prop, isEmpty, omit } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const addConstantProps = (clientid, itemType) => compose(assoc("clientid", clientid), assoc("itemType", itemType));

const callGetSubordinatorGuarantor = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_SUBORDINATOR_GUARANTOR, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/summary/subordinatorsandguarantors`);

    const apiCalls = [
      Axios.get(`/api/borrower/client/${clientid}/summary/subordinatorsandguarantors`),
      Axios.get(`/api/loans/client/${clientid}/bootcollateral`)
    ];

    const responses = await Promise.allSettled(apiCalls);
    const hasError = responses.find(r => r.status === "rejected");
    if (hasError || isEmpty(responses) || responses.length < 2) {
      commit(
        MU.SET_ERROR_SUBORDINATOR_GUARANTOR,
        logAndGetErrorMessage(
          AC.CALL_GET_SUBORDINATOR_GUARANTOR,
          "There was an error retreiving Subordination and Guarantors information."
        )
      );
    } else {
      const data = responses[0].value.data;
      data.bootCollateralLoanIds = responses[1].value.data;
      commit(MU.SET_INFORMATION_SUBORDINATOR_GUARANTOR, data);
    }
  } catch (err) {
    commit(MU.SET_ERROR_SUBORDINATOR_GUARANTOR, logAndGetErrorMessage(AC.CALL_GET_SUBORDINATOR_GUARANTOR, err));
  } finally {
    commit(MU.SET_LOADING_SUBORDINATOR_GUARANTOR, false);
  }
};

const saveSubordinatorGuarantor = async ({ commit, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;

  try {
    commit(MU.SET_LOADING_SUBORDINATOR_GUARANTOR, true);

    const apiCalls = [
      Axios.patch(
        `/api/borrower/client/${clientid}/summary/subordinatorsandguarantors`,
        assoc("clientid", clientid, omit(["bootCollateralLoanIds"], formData))
      ),
      Axios.patch(`/api/loans/client/${clientid}/bootcollateral`, JSON.stringify(formData.bootCollateralLoanIds))
    ];
    const responses = await Promise.allSettled(apiCalls);
    const hasError = responses.find(r => r.status === "rejected");
    if (hasError) {
      commit(
        MU.SET_ERROR_SUBORDINATOR_GUARANTOR,
        logAndGetErrorMessage(AC.SAVE_SUBORDINATOR_GUARANTOR, "There was an error saving Subordination and Guarantors.")
      );
    }

    commit(MU.SET_INFORMATION_SUBORDINATOR_GUARANTOR, formData);
  } catch (err) {
    commit(MU.SET_ERROR_SUBORDINATOR_GUARANTOR, logAndGetErrorMessage(AC.SAVE_SUBORDINATOR_GUARANTOR, err));
  } finally {
    commit(MU.SET_LOADING_SUBORDINATOR_GUARANTOR, false);
  }
};

const callGetSubordinator = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_SUBORDINATOR, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/SubordinationAndGuarantors`);

    const sg = filter(compose(equals("subordination"), prop("itemType")), data);
    const pg = filter(compose(equals("personalguarantor"), prop("itemType")), data);
    const cg = filter(compose(equals("corporateguarantor"), prop("itemType")), data);

    commit(MU.SET_LIST_SUBORDINATOR, sg);
    commit(MU.SET_LIST_PERSONAL_GUARANTOR, pg);
    commit(MU.SET_LIST_CORPORATE_GUARANTOR, cg);
  } catch (err) {
    commit(MU.SET_ERROR_SUBORDINATOR, logAndGetErrorMessage(AC.CALL_GET_SUBORDINATOR, err));
  } finally {
    commit(MU.SET_LOADING_SUBORDINATOR, false);
  }
};

const createSubordinator = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_SUBORDINATOR, true);
    const data = addConstantProps(clientid, "subordination")(formData);
    await Axios.post(`/api/borrower/helper/${clientid}/SubordinationAndGuarantors`, data);
    dispatch(AC.CALL_GET_SUBORDINATOR);
  } catch (err) {
    commit(MU.SET_ERROR_SUBORDINATOR, logAndGetErrorMessage(AC.CREATE_SUBORDINATOR, err));
  } finally {
    commit(MU.SET_LOADING_SUBORDINATOR, false);
  }
};

const createPersonalGuarantor = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_PERSONAL_GUARANTOR, true);
    const data = addConstantProps(clientid, "personalguarantor")(formData);
    await Axios.post(`/api/borrower/helper/${clientid}/SubordinationAndGuarantors`, data);
    dispatch(AC.CALL_GET_SUBORDINATOR);
  } catch (err) {
    commit(MU.SET_ERROR_PERSONAL_GUARANTOR, logAndGetErrorMessage(AC.CREATE_PERSONAL_GUARANTOR, err));
  } finally {
    commit(MU.SET_LOADING_PERSONAL_GUARANTOR, false);
  }
};

const createCorporateGuarantor = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_CORPORATE_GUARANTOR, true);
    const data = addConstantProps(clientid, "corporateguarantor")(formData);
    await Axios.post(`/api/borrower/helper/${clientid}/SubordinationAndGuarantors`, data);
    dispatch(AC.CALL_GET_SUBORDINATOR);
  } catch (err) {
    commit(MU.SET_ERROR_CORPORATE_GUARANTOR, logAndGetErrorMessage(AC.CREATE_CORPORATE_GUARANTOR, err));
  } finally {
    commit(MU.SET_LOADING_CORPORATE_GUARANTOR, false);
  }
};

const updateSubordinator = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_SUBORDINATOR, true);
    const data = addConstantProps(clientid, "subordination")(formData);
    await Axios.put(`/api/borrower/helper/${formData.id}/SubordinationAndGuarantors`, data);
    dispatch(AC.CALL_GET_SUBORDINATOR);
  } catch (err) {
    commit(MU.SET_ERROR_SUBORDINATOR, logAndGetErrorMessage(AC.UPDATE_SUBORDINATOR, err));
  } finally {
    commit(MU.SET_LOADING_SUBORDINATOR, false);
  }
};

const updatePersonalGuarantor = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_PERSONAL_GUARANTOR, true);
    const data = addConstantProps(clientid, "personalguarantor")(formData);
    await Axios.put(
      `/api/borrower/helper/${formData.id}/SubordinationAndGuarantors`,
      assoc("clientid", clientid, formData)
    );
    dispatch(AC.CALL_GET_SUBORDINATOR);
  } catch (err) {
    commit(MU.SET_ERROR_PERSONAL_GUARANTOR, logAndGetErrorMessage(AC.UPDATE_PERSONAL_GUARANTOR, err));
  } finally {
    commit(MU.SET_LOADING_PERSONAL_GUARANTOR, false);
  }
};

const updateCorporateGuarantor = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_CORPORATE_GUARANTOR, true);
    const data = addConstantProps(clientid, "corporateguarantor")(formData);
    await Axios.put(`/api/borrower/helper/${formData.id}/SubordinationAndGuarantors`, data);
    dispatch(AC.CALL_GET_SUBORDINATOR);
  } catch (err) {
    commit(MU.SET_ERROR_CORPORATE_GUARANTOR, logAndGetErrorMessage(AC.UPDATE_CORPORATE_GUARANTOR, err));
  } finally {
    commit(MU.SET_LOADING_CORPORATE_GUARANTOR, false);
  }
};

export const actions = {
  [AC.CALL_GET_SUBORDINATOR_GUARANTOR]: callGetSubordinatorGuarantor,
  [AC.SAVE_SUBORDINATOR_GUARANTOR]: saveSubordinatorGuarantor,
  [AC.CALL_GET_SUBORDINATOR]: callGetSubordinator,

  [AC.CREATE_SUBORDINATOR]: createSubordinator,
  [AC.CREATE_PERSONAL_GUARANTOR]: createPersonalGuarantor,
  [AC.CREATE_CORPORATE_GUARANTOR]: createCorporateGuarantor,

  [AC.UPDATE_SUBORDINATOR]: updateSubordinator,
  [AC.UPDATE_PERSONAL_GUARANTOR]: updatePersonalGuarantor,
  [AC.UPDATE_CORPORATE_GUARANTOR]: updateCorporateGuarantor
};
